import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setSelectMainMenu } from '../../modules/hompageSlice';
import { scrollToTop } from '../../util/scrollUtils';
import PAGE from '../../page/page';

import '../../css/section/footer.scss';

const FooterHomepage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectMainMenu = (menuName: string) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(menuName);
    scrollToTop();
  };
  return (
    <div className="footer">
      <div className="wrap-footer-top">
        <div className="wrap-footer-topleft">
          <div className="logo-footer logo-white">
            <span></span>
          </div>
          <div className="wrap-footer-location">
            <p className="font-small">
              서울시 구로구 디지털로 26길 123 지플러스타워 5층 501호
              <br />
              #501, 123, Digital-ro 26-gil, Guro-gu, Seoul, Republic of Korea
            </p>
            <p className="font-small">
              <span>T. +82.2.6959.0341 &nbsp;&nbsp;</span>
              <span>E. customer@ontwins.com &nbsp;&nbsp;</span>
              <span>F. +0505-099-0314</span>
            </p>
          </div>
        </div>

        <div className="wrap-footer-topright">
          <ul className="wrap-footer-menu">
            <li className="con-footer-menu">
              <p
                className="con-footer-menu-title"
                onClick={() => selectMainMenu(PAGE.ABOUT_US)}
              >
                About Us
              </p>
              <ul className="con-footer-menu-list">
                <li
                  className="font-small"
                  onClick={() => selectMainMenu(PAGE.ABOUT_US)}
                >
                  About Us
                </li>
                <li
                  className="font-small"
                  onClick={() => selectMainMenu(PAGE.LOCATION)}
                >
                  Location
                </li>
              </ul>
            </li>
            <li className="con-footer-menu">
              <p
                className="con-footer-menu-title"
                onClick={() => selectMainMenu(PAGE.SOL_DT)}
              >
                Solutions
              </p>
              <ul className="con-footer-menu-list">
                <li
                  className="font-small"
                  onClick={() => selectMainMenu(PAGE.SOL_DT)}
                >
                  Digital Twin
                </li>
                <li
                  className="font-small"
                  onClick={() => selectMainMenu(PAGE.SOL_VE)}
                >
                  Virtual
                  <br />
                  exhibtion
                </li>
                <li
                  className="font-small"
                  onClick={() => selectMainMenu(PAGE.SOL_M)}
                >
                  Metaverse
                </li>
              </ul>
            </li>
            <li
              className="con-footer-menu"
              onClick={() => selectMainMenu(PAGE.PROJECTS)}
            >
              <p className="con-footer-menu-title">Projects</p>
            </li>
            <li
              className="con-footer-menu"
              onClick={() => selectMainMenu(PAGE.BLOG)}
            >
              <p className="con-footer-menu-title">Blogs</p>
            </li>
            <li
              className="con-footer-menu"
              onClick={() => selectMainMenu(PAGE.CONTACT)}
            >
              <p className="con-footer-menu-title">Contact</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="wrap-footer-bottom">
        <p className="font-small">
          Copyright © 2025 IGI Korea All rights reserved.
        </p>
        <ul className="wrap-footer-sns">
          <li className="con-footer-sns">
            <a
              href="https://www.facebook.com/profile.php?id=100063967263526"
              target="_blank"
              className="facebook"
            ></a>
          </li>
          <li className="con-footer-sns">
            <a
              href="https://www.instagram.com/_ontwins_/"
              target="_blank"
              className="instargram"
            ></a>
          </li>
          <li className="con-footer-sns">
            <a
              href="https://twitter.com/_ontwins_"
              className="twitter"
              target="_blank"
            ></a>
          </li>
          <li className="con-footer-sns">
            <a
              href="https://www.linkedin.com/in/igi-korea-338597205/"
              target="_blank"
              className="linkedin"
            ></a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default React.memo(FooterHomepage);
